import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils/object';

export default deepMerge(grommet, {
  global: {
    font: {
      family: "'Metric', Arial, sans-serif",
      face: `
      @font-face {
        font-family: "Metric";
        src: url("https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Regular.woff") format('woff');
      }
      @font-face {
        font-family: "Metric";
        src: url("https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Bold.woff") format('woff');
        font-weight: 700;
      }
      @font-face {
        font-family: "Metric";
        src: url("https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Semibold.woff") format('woff');
        font-weight: 600;
      }
      @font-face {
        font-family: "Metric";
        src: url("https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Light.woff") format('woff');
        font-weight: 100;
      }
    `,
    },
  },
});
