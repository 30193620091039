import React, { useState } from 'react';
import {
  Grommet, Box, Image, FormField, TextInput, Button, Text, Anchor, Paragraph,
} from 'grommet';
import { Next } from 'grommet-icons';
import theme from './theme';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const isValidForm = email;
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isIE = false || !!document.documentMode;
  function submitEmail(formData) {
    setIsLoading(true);
    const endPoint = process.env.REACT_APP_API_URL || 'http://localhost:9000/sign-up';
    fetch(endPoint, {
      method: 'POST',
      body: JSON.stringify({
        email: formData.email,
      }),
    })
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch(e => console.log('error', e) || setIsLoading(false));
  }

  return (
    <Box>
      {!isIE ? (
        <Grommet full theme={theme}>
          <Box full height="100%" className="title-section" background="dark-1">
            <Box justify="end" height="100%" className="image-container">
              <Image className="top-image top-three" src="./img/top-third.png" />
              <Image className="top-image top-two" src="./img/top-second.png" />
              <Image className="hand" src="./img/hand.png" />
              <Image className="top-image top-one" src="./img/top-first.png" />
              <Box className="title-info-container" align="start" direction="column">
                <Box height="100%" direction="row">
                  <Image className="hpe-logo" src="./img/hpe-logo.png" />
                </Box>
                <Image margin={{ bottom: 'medium' }} className="metal-logo" src="./img/staight-metal.svg" />
                <Box direction="column" className="title-info-text-container">
                  <Text className="title-info-text" weight={400}>Hewlett Packard Enterprise would like to introduce,</Text>
                  <Box direction="row">
                    <Text className="title-info-text" weight={800}>HPE Metal</Text>
                    <Text className="title-info-text" weight={400}>. The fastest way to deploy Bare Metal as a</Text>
                  </Box>
                  <Text className="title-info-text" weight={400}>service for private and public clouds.</Text>
                  <Box pad={{ top: 'small' }}>
                    <Button
                      primary
                      color="brand"
                      label="Notify Me!"
                      onClick={(event) => {
                        event.preventDefault();
                        const signupContainer = document.getElementById('signup-container');
                        signupContainer.scrollIntoView();
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="content-container">
            <Box align="center" background="status-warning" className="content-section-1">
              <Image margin={{ bottom: 'medium' }} className="sword-image" src="./img/swords.png" />
              <Text margin={{ bottom: 'medium' }} weight={800} className="section-1-title">Rock out with your apps!</Text>
              <Text className="content-text-section-1">No extra services, no additional costs, no hidden fees, and no</Text>
              <Text className="content-text-section-1">fluff. We give you a place to jam, a thumpin&apos; price, and solid</Text>
              <Text className="content-text-section-1">reliability. It&apos;s just bare metal. Let&apos;s rock!</Text>
            </Box>
            <Box align="center" background="accent-4" className="content-section-2">
              <Image className="middle-image" src="./img/middle-image.png" />
              <Image margin={{ bottom: 'medium' }} className="skull-image" src="./img/skull.png" />
              <Text margin={{ bottom: 'medium' }} weight={800} className="section-1-title">Why jam with us?</Text>
              <Text className="content-text-section-1">It&apos;s about time we auditioned. We&apos;ve been playing some big gigs</Text>
              <Text className="content-text-section-1">behind the scenes, but it&apos;s time for us to start touring with you.</Text>
              <Text className="content-text-section-1">You get trusted HPE hardware with performance and price the</Text>
              <Text className="content-text-section-1">other venues can&apos;t compete with. It&apos;s time to jam.</Text>
            </Box>
          </Box>
          <Box  align="center" background="neutral-2" className="signup-container">
            <Image margin={{ bottom: 'medium' }} className="bottom-image" src="./img/bottom-image.png" />
            <Text margin={{ bottom: 'medium' }} weight={800} className="section-1-title">The metal is coming!</Text>
            <Text className="content-text-section-1">Be the first to know what happens next with HPE Metal. The next</Text>
            <Text className="content-text-section-1">email you&apos;ll get from us is when Metal is ready!</Text>
            <Box pad="medium" width="medium">
              {success && (
                <Text textAlign="center">
                  Thanks! We{'\''}ll be in touch.
                </Text>
              )}
              {!success && (
                <form>
                  <fieldset disabled={isLoading}>
                    <FormField htmlFor="email">
                      <TextInput
                        placeholder="Your Email"
                        disabled={isLoading}
                        value={email}
                        id="email"
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormField>
                    <Box pad={{ top: 'small', left: 'large', right: 'large' }}>
                      <Button
                        primary
                        reverse
                        disabled={isLoading || !isValidForm}
                        type="submit"
                        label={isLoading ? 'loading...' : 'Notify Me!'}
                        onClick={(event) => {
                          event.preventDefault();
                          if (email) {
                            submitEmail({ email });
                          }
                        }}
                      />
                    </Box>
                  </fieldset>
                </form>
              )}
            </Box>
            <Box id="signup-container" width="100%" alignSelf="start" className="footer">
              <Box justify="between" margin={{ left: 'small', bottom: 'small' }} direction="row-responsive">
                <Box direction="row">
                  <Image alignSelf="center" margin={{ right: 'small' }} className="copyright-logo" src="./img/hpe-logo-straight.png" />
                  <Text alignSelf="center" size="small" className="copyright-text">© 2019 Hewlett Packard Enterprise</Text>
                </Box>
                <Box direction="row-responsive">
                  <Text margin={{ right: 'xsmall' }} className="footer-text" color="light-6" size="xsmall">HPE Metal is a trademark of Hewlett Packard Enterprise</Text>
                  <Box direction="row">
                    <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/legal/privacy.html" size="xsmall" label="Privacy" />
                    <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/about/legal/terms-of-use.html" size="xsmall" label="Terms" />
                    <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/legal/privacy.html#accordion-accordioncontainerco-4" size="xsmall" label="Cookies" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grommet>
      ) : (
        <Grommet style={{ minHeight: '1080px' }} theme={theme} align="center">
          <Box direction="column" background="dark-1" align="center" className="ie-header" pad="small">
            <Box direction="column">
              <Box direction="row" alignSelf="end" justify="end" margin="small">
                <Image style={{ width: 'auto', height: 'auto', maxWidth: '50px' }} alignSelf="center" margin={{ right: 'small' }} src="./img/hpe-logo-straight.png" />
                <Text alignSelf="center" size="medium" className="copyright-text">Hewlett Packard Enterprise</Text>
              </Box>
              <Image style={{ width: 'auto', height: 'auto', maxHeight: '100px' }} src="./img/staight-metal.svg" />
            </Box>
            <Box alignSelf="center" align="center" direction="column" className="title-info-text-container" margin={{ top: 'large' }}>
              <Text className="ie-title-text" size="xxlarge" weight={400}>Hewlett Packard Enterprise would like to introduce,</Text>
              <Box direction="row">
                <Text className="ie-title-text" size="xxlarge" weight={800}>HPE Metal</Text>
                <Text className="ie-title-text" size="xxlarge" weight={400}>. The fastest way to deploy Bare Metal as a</Text>
              </Box>
              <Text className="ie-title-text" size="xxlarge" weight={400}>service for private and public clouds</Text>
            </Box>
            <Box margin="medium">
              <Button
                primary
                color="brand"
                label="Notify Me!"
                onClick={(event) => {
                  event.preventDefault();
                  const ieFooter = document.getElementById('ie-footer');
                  ieFooter.scrollIntoView();
                }}
              />
            </Box>
          </Box>
          <Box className="ie-content" background="status-warning" pad="small">
            <Box className="ie-content-section" align="center" background="status-warning" height="475px">
              <Image style={{ width: 'auto', height: 'auto', maxHeight: '40%' }} src="./img/swords.png" margin={{ top: 'medium' }} />
              <Text className="ie-content-text-title" margin={{ bottom: 'small', top: 'medium' }} weight={800} size="xxlarge">Rock out with your apps!</Text>
              <Text className="ie-content-text" size="xlarge">No extra services, no additional costs, no hidden fees, and no</Text>
              <Text className="ie-content-text" size="xlarge">fluff. We give you a place to jam, a thumpin&apos; price, and solid</Text>
              <Text className="ie-content-text" size="xlarge">reliability. It&apos;s just bare metal. Let&apos;s rock!</Text>
            </Box>
          </Box>
          <Box className="ie-content" background="accent-4" pad="small">
            <Box className="ie-content-section" align="center" background="accent-4" height="475px">
              <Image style={{ width: 'auto', height: 'auto', maxHeight: '30%', maxWidth: '350px' }} margin={{ top: 'medium' }} src="./img/skull.png" />
              <Text className="ie-content-text-title" margin={{ bottom: 'small', top: 'medium' }} weight={800} size="xxlarge">Why jam with us?</Text>
              <Text className="ie-content-text" size="xlarge">It&apos;s about time we auditioned. We&apos;ve been playing some big gigs</Text>
              <Text className="ie-content-text" size="xlarge">behind the scenes, but it&apos;s time for us to start touring with you.</Text>
              <Text className="ie-content-text" size="xlarge">You get trusted HPE hardware with performance and price the</Text>
              <Text className="ie-content-text" size="xlarge">other venues can&apos;t compete with. It&apos;s time to jam.</Text>
            </Box>
          </Box>
          <Box align="center" className="ie-footer" background="neutral-2">
            <Text className="ie-content-text-title" margin={{ bottom: 'medium', top: 'large' }} weight={800} size="xxlarge">The metal is coming!</Text>
            <Text className="ie-content-text" size="xlarge">Be the first to know what happens next with HPE Metal. The next</Text>
            <Text className="ie-content-text" size="xlarge">email you&apos;ll get from us is when Metal is ready!</Text>
            <Box pad="medium" width="medium">
              {success && (
                <Text textAlign="center">
                  Thanks! We{'\''}ll be in touch.
                </Text>
              )}
              {!success && (
                <form>
                  <fieldset disabled={isLoading}>
                    <FormField htmlFor="email">
                      <TextInput
                        placeholder="Your Email"
                        disabled={isLoading}
                        value={email}
                        id="email"
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormField>
                    <Box pad={{ top: 'small', left: 'large', right: 'large' }}>
                      <Button
                        primary
                        reverse
                        disabled={isLoading || !isValidForm}
                        type="submit"
                        label={isLoading ? 'loading...' : 'Notify Me!'}
                        onClick={(event) => {
                          event.preventDefault();
                          if (email) {
                            submitEmail({ email });
                          }
                        }}
                      />
                    </Box>
                  </fieldset>
                </form>
              )}
            </Box>
            <Box id="ie-footer" direction="column">
              <Box justify="center" direction="row">
                <Image style={{ width: 'auto', height: 'auto', maxWidth: '50px' }} alignSelf="center" margin={{ right: 'small' }} src="./img/hpe-logo-straight.png" />
                <Text alignSelf="center" size="small" className="copyright-text">© 2019 Hewlett Packard Enterprise</Text>
              </Box>
              <Box direction="row-responsive">
                <Text margin={{ right: 'xsmall' }} className="footer-text" color="light-6" size="xsmall">HPE Metal is a trademark of Hewlett Packard Enterprise</Text>
                <Box justify="center" direction="row">
                  <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/legal/privacy.html" size="xsmall" label="Privacy" />
                  <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/about/legal/terms-of-use.html" size="xsmall" label="Terms" />
                  <Anchor margin={{ right: 'xsmall' }} target="_blank" rel="noopener noreferrer" href="https://www.hpe.com/us/en/legal/privacy.html#accordion-accordioncontainerco-4" size="xsmall" label="Cookies" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grommet>
      )}
    </Box>
  );
}

export default App;
